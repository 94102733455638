<template>
  <call-schedule-table/>
</template>
<script>
  import CallScheduleTable from './CallScheduleTable'

  export default {
    name: "CallSchedule",
    components: {
      CallScheduleTable,
    }
  }
</script>
<style lang="scss" scoped>
  .leblu2 {
    color: $rb-blue !important;
  }
</style>
