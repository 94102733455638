<template>
  <vuestic-widget :loading="loading">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Scheduled Calls</span>
        <button
          class="btn btn-primary btn-sm mr-2"
          @click="onRefresh()"
          :disabled="loading"
        >
          <span>Refresh <i class="fa fa-refresh"></i></span>
        </button>
      </div>
    </template>
    <div class="pb-2 mb-3">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
            <div class="">
              <date-range-picker
                ref="picker"
                :opens="filter.date.opens"
                :locale-data="{ firstDay: 1, format: 'MMMM D, YYYY' }"
                :single-date-picker="filter.date.singleDatePicker"
                :time-picker="filter.date.timePicker"
                :time-picker24-hour="filter.date.timePicker24Hour"
                :show-week-numbers="filter.date.showWeekNumbers"
                :show-dropdowns="filter.date.showDropdowns"
                :auto-apply="filter.date.autoApply"
                :always-show-calendars="filter.date.alwaysShowCalendars"
                :linked-calendars="filter.date.linkedCalendars"
                :ranges="filter.date.ranges"
                v-model="filter.date.dateRange"
                @update="handleQueryChange"
              >
              </date-range-picker>
            </div>
            <div class="d-flex align-items-center justify-content-end flex-row mr-2">
              <search-input
                name="searchInput"
                v-model="searchFilter"
                @input="onChangeFilter"
              />
            </div>
            <div class="">
              <button
                class="btn btn-sm btn-primary pull-right"
                @click="onResetFilter()"
              >
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <datatable v-bind="tableConfig" class="le-datatable"/>
    <vuestic-modal
      :isOpen="isCancelModalOpen"
      @ok="handleCancel"
      @cancel="isCancelModalOpen = false"
      okText="Cancel This Call"
      :cancelShown="false"
      :closeOnOk="false"
      :processing="processing"
      okClass="btn btn-danger btn-primary"
    >
      <span slot="title" class="text-danger font-weight-bold">Cancel This Schedule Call?</span>

      <div>Confirm you want to cancel a scheduled call {{ selectedCall ? 'from ' + this.$options.filters.phone(selectedCall['client_phone']) : '' }} ?</div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
  import axios from 'axios'
  import components from "../../common/tables/comps";
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import ScheduledMore from "./Action/ScheduledMore";
  import SearchInput from '../../common/SearchInput'
  import Moment from 'moment'
  import { extendMoment } from 'moment-range'

  const moment = extendMoment(Moment)

  export default {
    components: {
      SearchInput,
      DateRangePicker
    },

    data() {
      return {
        loading: false,
        isCancelModalOpen: false,
        selectedCall: null,
        processing: false,
        tableConfig: {
          supportBackup: false,
          supportNested: false,
          HeaderSettings: false,
          tblClass: "table-bordered",
          tblStyle: "color: #666",
          pageSizeOptions: [10, 25, 50, 100],
          columns: (() => {
            return [
              { title: "ID", field: "id", label: "Call Schedule ID", tdClass: "center", sortable: true, visible: false },
              { title: "Status", sortable: true, field: "status_str", tdClass: "left", thClass: "left", tdStyle: {fontStyle: "normal"} },
              { title: "Website", sortable: true, field: "client_referer_url", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedValue" },
              { title: "Department", sortable: true, field: "department.name", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedValue" },
              { title: "Scheduled Date", sortable: true, field: "date", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedDateValue" },
              { title: "Phone", sortable: true, field: "client_phone", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdPhone" },
              { title: "Name", sortable: false, field: "contact_full_name", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedValue" },
              { title: "Action", tdComp: ScheduledMore, visible: 'true', tdClass: 'center'},
            ];
          })(),
          data: [],
          total: 0,
          // selection: [],  //if this is present the row selector shows up..
          summary: {},
          query: {},
          // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
          xprops: {
            eventbus: new Vue()
          },
        },
        filter: {
          date : {
            opens: 'right',
            minDate: null,
            maxDate: null,
            singleDatePicker: false,
            timePicker: false,
            timePicker24Hour: false,
            showWeekNumbers: false,
            showDropdowns: false,
            autoApply: false,
            dateRange: {
              startDate: moment().subtract(30, 'days'),
              endDate: moment(),
            },
            linkedCalendars: true,
            ranges: {
              'Today': [moment(), moment()],
              'Last 7 Days': [moment().subtract(7, 'days'), moment()],
              'Last 30 Days': [moment().subtract(30, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            alwaysShowCalendars: false,
          }
        },
        searchFilter: null,
      };
    },
    created() {
      this.tableConfig.xprops.eventbus.$on('openCancel', row => {
        this.openCancelModal(row);
      })
    },
    watch: {
      "tableConfig.query": {
        handler() {
          this.handleQueryChange();
        },
        deep: true
      }
    },
    methods: {
      onRefresh() {
        this.tableConfig.query.offset = 0
        this.handleQueryChange()
      },

      handleQueryChange() {
        this.loading = true;
        let queries = {
          ...this.tableConfig.query,
          search: this.searchFilter,
          started_at: this.filter.date.dateRange.startDate ? moment(this.filter.date.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : null,
          ended_at: this.filter.date.dateRange.endDate ? moment(this.filter.date.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : null,
        }

        axios.get(`/v1/reservation/call`, { params: queries })
          .then((res) => {
            this.tableConfig.data = res.data.rows;
            this.tableConfig.total = res.data.total;
            this.loading = false;
          }).catch((e) => {
            this.loading = false;
        });
      },

      openCancelModal(item) {
        this.selectedCall = item
        this.isCancelModalOpen = true
      },

      onChangeFilter() {
        this.handleQueryChange()
      },

      onResetFilter() {
        this.searchFilter = null;
        this.handleQueryChange()
      },

      handleCancel() {
        this.processing = true

        axios.delete(`/v1/reservation/call/${this.selectedCall.id}`, this.tableConfig.query)
          .then((res) => {
            this.processing = false;
            this.isCancelModalOpen = false
            this.selectedCall.status = 'cancelled'
            this.selectedCall.status_str = 'Cancelled'
          }).catch((e) => {
            this.processing = false;
        });
      },
    }
  };
</script>

<style lang="scss">
.vue-daterange-picker {
  display: block !important;

  //make range picker behave as adequate input
  .reportrange-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.45rem 0.75rem;
    border: .0625rem solid #e7e7e7;
    height: inherit;
  }

  .daterangepicker {
    transform: scale(1);
    opacity: 1;

    //Move ranges list to the right
    .ranges {
      order: 10;
    }
  }
}
</style>